import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby-link';
// import ReCAPTCHA from 'react-google-recaptcha';

// Maybe add Accordion sections for each location addition... x to remove
// import Accordion from '../components/accordion';
// import USI from '../images/usi.jpg';

import Layout from '../components/layout';
import SEO from '../components/seo';

// styled components
import Title from '../components/styles/title';
import ContactUsStyles from '../components/styles/contact-us-styles';

const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;

  .checkbox,
  input {
    margin-bottom: 0;
    height: auto;
  }
`;

const Header = styled.h4`
  border-bottom: 1px solid black;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0;
  text-align: left;
`;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function encode(data) {
  return (
    Object.keys(data)
      // eslint-disable-next-line prefer-template
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  );
}

const ContactUs = () => {
  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    // eslint-disable-next-line no-undef
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      });
  };

  return (
    <Layout>
      <SEO title="USI Insurance Form" />
      <Title>Request A Quote</Title>

      {/* Add Logo and Bio Section here??? Review with Todd    */}
      {/* <div>
        <img src={USI} alt="USI logo" />
        <p>
          Understand. Service. Innovate
        </p>
        <p>
          The USI insurance program provides group coverage on Property, Casualty
          & Workers Compensation insurance. Individual owners enjoy the benefit
          of lower premiums and the assurance of reduced risk through large group
          participation.
        </p>
        <p>
          The IOLCF has endorsed a Property & Casualty insurance program called
          Risk Control Solutions. USI Insurance Services, Inc. is the administrator
          for this group program.
        </p>
        <p>
          The USI program provides individual operators with the benefit of reduced
          premiums and the assurance of risk reduction through large group participation.
          Risk Control Solutions focuses on communicating Best Practices and Claims Solutions
          amongst our members.
        </p>
        <p>
          Franchisees are seeing immediate premium savings through this insurance
          program and also receive further savings through royalty returns.
          While membership in the IOLCF is not required for insurance, the program
          currently pays up to 5% of premium back to participating IOLCF member stores.
        </p>
      </div> */}

      <ContactUsStyles>
        <div className="ContactUs__container">
          {/* <h3>
            Press “+ Location” at the bottom of the page to add additional locations to your quote.
          </h3> */}
          {/* TODO: Add additional locations to the insurance form 
          functionality on click of button at bottom of the page */}

          <form
            name="usi-request-quote"
            method="post"
            action="/thanks"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to 
            support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="usi-request-quote" />
            <p hidden>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                Don’t fill this out:
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <Header>Contact Information:</Header>
            <span>Business Name *</span>
            <input
              className="inputStyle"
              name="Business Name"
              onChange={handleChange}
              placeholder="Business Name *"
              required
              type="text"
            />
            <span>DBA</span>
            <input
              className="inputStyle"
              name="DBA"
              onChange={handleChange}
              placeholder="DBA"
              type="text"
            />
            <span>Owner / Insurance Contact</span>
            <input
              className="inputStyle"
              name="Owner/Insurance Contact"
              onChange={handleChange}
              placeholder="Owner / Insurance Contact"
              type="text"
            />
            <span>Phone Number *</span>
            <input
              className="inputStyle"
              name="Phone Number"
              onChange={handleChange}
              placeholder="Phone Number *"
              required
              type="tel"
            />
            <span>Email</span>
            <input
              className="inputStyle"
              name="Email"
              onChange={handleChange}
              placeholder="Email"
              required
              type="email"
            />
            <span>Best Time To Contact</span>
            <input
              className="inputStyle"
              name="Best Time To Contact"
              onChange={handleChange}
              placeholder="Best Time To Contact"
              type="time"
            />
            <Header>Quotes Needed:</Header>
            <CheckboxLabel className="checkboxLabel">
              <input
                className="inputStyle checkbox"
                name="Business Insurance"
                onChange={handleChange}
                placeholder="Business Insurance"
                type="checkbox"
              />
              <span>Business Insurance</span>
            </CheckboxLabel>
            <CheckboxLabel className="checkboxLabel">
              <input
                className="inputStyle checkbox"
                name="Work Comp"
                onChange={handleChange}
                placeholder="Work Comp"
                type="checkbox"
              />
              <span>Work Comp</span>
            </CheckboxLabel>
            <CheckboxLabel className="checkboxLabel">
              <input
                className="inputStyle checkbox"
                name="Auto"
                onChange={handleChange}
                placeholder="Auto"
                type="checkbox"
              />
              <span>Auto</span>
            </CheckboxLabel>
            <CheckboxLabel className="checkboxLabel">
              <input
                className="inputStyle checkbox"
                name="Umbrella"
                onChange={handleChange}
                placeholder="Umbrella"
                type="checkbox"
              />
              <span>Umbrella</span>
            </CheckboxLabel>
            <Header>Business Insurance Information:</Header>
            <span>Effective Date</span>
            <input
              className="inputStyle"
              name="Effective Date"
              onChange={handleChange}
              placeholder="Effective Date"
              type="date"
            />
            <span>Number of Store Locations</span>
            <input
              className="inputStyle"
              name="Number of Store Locations"
              onChange={handleChange}
              placeholder="# of Store Locations"
              type="number"
            />
            <Header>Location Information:</Header>
            {/* TODO: REPEAT LOACATIONS HERE +  */}
            {}
            <LocationContainer>
              <h4>Address:</h4>
              <span>Street Address</span>
              <input
                className="inputStyle"
                name="Street Address:"
                onChange={handleChange}
                placeholder="Street Address:"
                type="text"
              />
              <span>City</span>
              <input
                className="inputStyle"
                name="City"
                onChange={handleChange}
                placeholder="City"
                type="text"
              />
              <span>State / Zip Code</span>
              <input
                className="inputStyle"
                name="State/Zip Code"
                onChange={handleChange}
                placeholder="State / Zip Code"
                type="text"
              />
              <span>Do you own the building?</span>
              <CheckboxLabel className="checkboxLabel">
                <input
                  className="inputStyle"
                  name="Yes"
                  onChange={handleChange}
                  placeholder="Yes"
                  type="radio"
                />
                <span>Yes</span>
              </CheckboxLabel>
              <CheckboxLabel className="checkboxLabel">
                <input
                  className="inputStyle"
                  name="No"
                  onChange={handleChange}
                  placeholder="No"
                  type="radio"
                />
                <span>No</span>
              </CheckboxLabel>
              <Header>Insurance Limits:</Header>
              <span>Building Limit (if owned/or the lease requires)</span>
              <input
                className="inputStyle"
                name="Building Limit (if owned/or the lease requires):"
                onChange={handleChange}
                placeholder="Building Limit"
                type="text"
              />
              <span>Business Personal Property</span>
              <input
                className="inputStyle"
                name="Business Personal Property"
                onChange={handleChange}
                placeholder="Business Personal Property"
                type="text"
              />
              <span>Sales</span>
              <input
                className="inputStyle"
                name="Sales"
                onChange={handleChange}
                placeholder="Sales"
                type="text"
              />
              <Header>Underwriting Information/Building Information:</Header>
              <span>Year Built</span>
              <input
                className="inputStyle"
                name="Year Built"
                onChange={handleChange}
                placeholder="Year Built"
                type="text"
              />
              <span>Sq. Footage</span>
              <input
                className="inputStyle"
                name="Sq. Footage"
                onChange={handleChange}
                placeholder="Sq. Footage"
                type="text"
              />
              <span>Do you offer delivery?</span>
              <CheckboxLabel className="checkboxLabel">
                <input
                  className="inputStyle"
                  name="Yes"
                  onChange={handleChange}
                  placeholder="Yes"
                  type="radio"
                />
                <span>Yes</span>
              </CheckboxLabel>
              <CheckboxLabel className="checkboxLabel">
                <input
                  className="inputStyle"
                  name="No"
                  onChange={handleChange}
                  placeholder="No"
                  type="radio"
                />
                <span>No</span>
              </CheckboxLabel>
              <Header>Workers Compensation:</Header>
              <span>Number of Store Employees</span>
              <input
                className="inputStyle"
                name="Number of Store Employees"
                onChange={handleChange}
                placeholder="# of Store Employees"
                type="number"
              />
              <span>Payroll</span>
              <input
                className="inputStyle"
                name="Payroll"
                onChange={handleChange}
                placeholder="Payroll"
                type="number"
              />
              <p>Owner to be:</p>
              <CheckboxLabel className="checkboxLabel">
                <input
                  className="inputStyle"
                  name="Included"
                  onChange={handleChange}
                  placeholder="Included"
                  type="radio"
                />
                <span>Included</span>
              </CheckboxLabel>
              <CheckboxLabel className="checkboxLabel">
                <input
                  className="inputStyle"
                  name="Excluded"
                  onChange={handleChange}
                  placeholder="Excluded"
                  type="radio"
                />
                <span>Excluded</span>
              </CheckboxLabel>
            </LocationContainer>
            {/* <button
              className="JoinNow__button"
              type="submit"
              onChange={handleChange}
            >
              + Location
            </button> */}

            {/* <ReCAPTCHA sitekey="YOUR_SITE_KEY" /> */}
            <button
              className="JoinNow__button"
              type="submit"
              onChange={handleChange}
            >
              submit
            </button>
          </form>
        </div>
      </ContactUsStyles>
    </Layout>
  );
};

export default ContactUs;
